import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function NotFoundPage()
{
  return (
    <Layout>
      <Seo title="404: Page Not Found" />

      <div className="flex flex-col items-center justify-center space-y-4 h-screen max-w-screen-sm mx-auto px-5">
        <div className="font-medium text-center text-gray-500" style={{ fontSize: '2.2vh' }}>Page Not Found.</div>
        <div className="font-medium text-center text-gray-500" style={{ fontSize: '2.2vh' }}>How about going <Link to="/" className="font-cursive text-dark-orange text-2xl">Home</Link> ?</div>
      </div>

    </Layout>
  )
}

export default NotFoundPage